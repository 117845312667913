@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Abel";
  src: url("/public/fonts/Abel-Regular.ttf");
}

@font-face {
  font-family: "ClashGrotesk";
  src: url("/public/fonts/ClashGrotesk-Regular.ttf");
}

@font-face {
  font-family: "ClashGrotesk-Medium";
  src: url("/public/fonts/ClashGrotesk-Medium.ttf");
}

@font-face {
  font-family: "ClashGrotesk-Semibold";
  src: url("/public/fonts/ClashGrotesk-Semibold.ttf");
}

@font-face {
  font-family: "Astrella";
  src: url("/public/fonts/Astrella.ttf");
}

@layer base {
  html {
    font-family: Abel;
  }
}

body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  /* overflow-x: hidden; */
}

input,
select,
textarea,
button,
.form-control,
.input-field:focus,
.input-field:active {
  outline: 0px !important;
  -webkit-appearance: none !important;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none !important;
}

.primary {
  color: #0B60D6;
}

.b_shadow {
  box-shadow: 0px 5px 10.9px 0px rgba(21, 21, 21, 0.23);
}

.bg_talent {
  background: url(assets/images/icons/talent-acquisition-25thblue.svg) no-repeat right bottom;
  background-size: 300px;
  height: 100%;
}

.bg_job {
  background: url(assets/images/icons/job-placement-25thblue.svg) no-repeat right bottom;
  background-size: 235px;
  height: 100%;
}

.bg_ondemand {
  background: url(assets/images/icons/on-demand-staffing-25thblue.svg) no-repeat right bottom;
  background-size: 263px;
  height: 100%;
}

.bg_training {
  background: url(assets/images/icons/training-and-development-25thblue.svg) no-repeat right bottom;
  background-size: 252px;
  height: 100%;
}

.ant-steps {
  display: none !important;
}

@media only screen and (min-width: 240px) and (max-width: 767px) {
  .about_img {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about_img {
    display: none !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .about_img {
    display: none !important;
  }
}